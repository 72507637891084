import { render, staticRenderFns } from "./annals.vue?vue&type=template&id=fae1defe&scoped=true&"
import script from "./annals.vue?vue&type=script&lang=js&"
export * from "./annals.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fae1defe",
  null
  
)

export default component.exports